.event-row--locked {
    background-color: rgba(200, 200, 200, 0.1);
}
.event-row--locked .MuiDataGrid-cellContent {
    opacity: 0.6;
}

.request-row--approved {
    background-color: rgba(200, 200, 200, 0.1);
}
.request-row--approved .MuiDataGrid-cellContent,
.request-row--approved .MuiChip-root {
    opacity: 0.6;
}